import React from 'react';
import SiteHead from './Header/SiteHead';
import Footer from './Footer/Footer';
import Navbar from './Header/Navbar';
import '../base/typography.css';
import '../base/siteWide.css';
import '../base/grid.css';

const TemplateWrapper = (props) => {
  let nav = typeof props.nav === 'boolean' ? props.nav : props.nav;
  return (
    <div>
      <SiteHead />
      { nav &&
        <Navbar {...props.navBar} />
      }
      <div>{props.children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
