import React from 'react';
import { Link } from 'gatsby';
import { FaFacebook, FaInstagram, FaEnvelope } from 'react-icons/fa';
import logo from '../../base/img/homebox-logo-small-white-2-transparent.png';

import styles from './Footer.css'; /* eslint-disable-line */

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-standard">
          <div className="footer__top grid-medium-up">
            <div className="grid-item grid-item--shrink">
              <img src={logo} className="logo--footer" alt="Homebox logo" />
            </div>
            <div className="footer__menu grid-item grid-medium-up">
              <div className="grid-item">
                <h3 className="footer__menu-title">Policies</h3>
                <ul>
                  <li><Link to="/legal/terms-and-conditions">Terms</Link></li>
                  <li><Link to="/legal/privacy-policy">Privacy Policy</Link></li>
                  <li><Link to="/legal/cookie-policy">Cookie Policy</Link></li>
                </ul>
              </div>
            </div>
            <div className="footer__social grid-item grid-item--shrink">
              <h3 className="footer__social-header">Connect With Us</h3>
              <div>
                <a href="https://www.facebook.com/homeboxuk/" target="_blank" rel="noopener noreferrer" className="footer__social-link social__facebook">
                  <span>Facebook</span><FaFacebook size={42} />
                </a>
                <a href="https://twitter.com/homebox_uk" target="_blank" rel="noopener noreferrer" className="footer__social-link social__twitter">
                  <span>Instagram</span><FaInstagram size={42} />
                </a>
                <a href="mailto:hi@homebox.co.uk" target="_blank" rel="noopener noreferrer" className="footer__social-link social__twitter">
                  <span>Email us</span><FaEnvelope size={42} />
                </a>
              </div>
            </div>
          </div>
          <div className="footer__footnotes">
            <div className="footer__copyright">© <strong>HOMEBOX.IO LTD</strong> 2020+. All rights reserved.</div>
            <div><small><strong>HOMEBOX.IO LTD</strong> is a company registered in England and Wales, company number 10544547, 1E Mentmore Terrace, London, E8 3DQ.</small></div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
