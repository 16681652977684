import React from 'react';
import { Link } from 'gatsby';
import { FaAngleRight } from 'react-icons/fa';
import logo from '../../base/img/homebox-logo-small-transparent.png';
import logoLight from '../../base/img/homebox-logo-small-white-2-transparent.png';

import styles from './Navbar.css'; /* eslint-disable-line */

const Navbar = class extends React.Component {
  render() {
    let navClass = 'nav';
    if (this.props.position && this.props.position === 'floating') {
      navClass = 'nav nav--floating';
    }
    let logoType = logo;
    if (this.props.style && this.props.style === 'light') {
      logoType = logoLight;
    }
    return (
      <nav className={navClass}>
        <div className="nav-branding">
          <Link to="/">
            <img src={logoType} className="logo" alt="Homebox" />
          </Link>
        </div>
        <div className="nav-menu__wrapper">
          <a href="/#savings" className="button button--primary">Get Started&nbsp; <FaAngleRight /></a>
        </div>
      </nav>
    )
  }
}

export default Navbar;
