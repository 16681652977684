import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from './SiteMetadata';
import { withPrefix } from 'gatsby';

// Site header.
// Helmet does not support inline conditionals, so we need to return
// different components depending on the environment.
const SiteHead = ({ children }) => {
  const { title, description } = useSiteMetadata();
  if (process.env.GATSBY_ACTIVE_ENV === 'production') {
    return (
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* For staging site, don't index */}
        { process.env.GATSBY_HOSTING === 'staging' &&
          <meta name="robots" content="noindex,nofollow" />
        }

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}images/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}images/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}images/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}images/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}images/og-image.jpg`}
        />

        <link href="https://fonts.googleapis.com/css?family=Hind:300,400,600|Open+Sans:400,600" rel="stylesheet" />

        {/* Sentry */}
        <script src="https://browser.sentry-cdn.com/5.17.0/bundle.min.js" integrity="sha384-lowBFC6YTkvMIWPORr7+TERnCkZdo5ab00oH5NkFLeQUAmBTLGwJpFjF6djuxJ/5" crossorigin="anonymous"></script>
        <script>{`
          window.addEventListener('DOMContentLoaded', function() {
            Sentry.init({ dsn: 'https://bb1d4ffebe0343e094092e33cbe5e73f@o73387.ingest.sentry.io/5283811' });
          });
        `}</script>

        {/* <!-- Hotjar Tracking Code for https://ittria.homebox.co.uk --> */}
        <script>{`
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:1919521,hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `}
        </script>

        {/* Global site tag (gtag.js) - Google Analytics */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-88133832-5"></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-88133832-5');
        `}</script>

        <script type="text/javascript">{`
        (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
        ;r.type="text/javascript"
        ;r.integrity="sha384-RsEu4WZflrqYcEacpfoGSib3qaSvdYwT4D+DrWqeBuDarSzjwUQR1jO8gDiXZd0E"
        ;r.crossOrigin="anonymous";r.async=true
        ;r.src="https://cdn.amplitude.com/libs/amplitude-6.2.0-min.gz.js"
        ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
        console.log("[Amplitude] Error: could not load SDK")}}
        ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
        ;function s(e,t){e.prototype[t]=function(){
        this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
        var o=function(){this._q=[];return this}
        ;var a=["add","append","clearAll","prepend","set","setOnce","unset"]
        ;for(var u=0;u<a.length;u++){s(o,a[u])}n.Identify=o;var c=function(){this._q=[]
        ;return this}
        ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
        ;for(var p=0;p<l.length;p++){s(c,l[p])}n.Revenue=c
        ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
        ;function v(e){function t(t){e[t]=function(){
        e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
        for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
        e=(!e||e.length===0?"$default_instance":e).toLowerCase()
        ;if(!n._iq.hasOwnProperty(e)){n._iq[e]={_q:[]};v(n._iq[e])}return n._iq[e]}
        ;e.amplitude=n})(window,document);

        amplitude.getInstance().init("0a743d268cfa65f0174ab76e8213df8b", null, {
            includeReferrer: true,
            includeUtm: true
        });

        var pathArray = window.location.pathname.split( '/' );
        if (pathArray && pathArray[1]) {
          var i;
          var pathObj = {};
          for (i = 0; i < pathArray.length; i++) {
            pathObj['path-' + i] = pathArray[i];
          }
          if (window.location.search) {
            pathObj.search = window.location.search;
          }
          amplitude.getInstance().logEvent('Visited page', pathObj);
        } else {
          amplitude.getInstance().logEvent('Visited page', {'path-0': '<front>'});
        }
        `}</script>

      </Helmet>
    )
  } else {
    // Local development.
    return (
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix('/')}images/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}images/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix('/')}images/favicon-16x16.png`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix('/')}images/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix('/')}images/og-image.jpg`}
        />

        <link href="https://fonts.googleapis.com/css?family=Hind:300,400,600|Open+Sans:400,600" rel="stylesheet" />

      </Helmet>
    )
  }
}

export default SiteHead;
